import { Link } from "gatsby";
import React, { useState } from "react";
import logo from './quergedacht_light.svg'

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-qblue">
      <div className='bg-gradient-to-r from-qorange to-qorange2 h-1'></div>
      <div className="flex flex-wrap items-center justify-between max-w-5xl p-4 mx-auto md:p-8">
        <Link to="/">
          <p className="flex items-center text-white no-underline">
            <img src={logo} alt="Logo" className="h-20" />
          </p>
        </Link>

        <button
          className="flex items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `Start`,
            },
            {
              route: `/quergedacht`,
              title: `quergedacht`,
            },
            {
              route: `/angebote`,
              title: `Unsere Angebote`,
            },
            {
              route: `/kontakt`,
              title: `Kontakt`,
            },
            {
              route: `/stellen`,
              title: `Stellenanzeigen`,
            }
          ].map((link) => (
            <Link
              // className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6 rounded p-2 border hover:border-red-500 hover:bg-blue-500"
              className="transition duration-700 ease-in-out block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6 rounded p-2 font-bold hover:text-qorange"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
