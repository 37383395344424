import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import xing from '../images/xing.svg'
import indeed from '../images/indeed.svg'

const Layout = ({ children }) => {

  return (
    <div className="flex flex-col min-h-screen font-sans">
      <Header />

      <main className="flex-1 w-full max-w-5xl px-4 py-8 mx-auto md:px-8 md:py-16">
        {children}
      </main>

      <footer className="bg-gray-200">

      <nav className='flex items-center p-4 md:p-8  max-w-5xl p-4 mx-auto '>

          <div className='flex-grow'></div>
            
            <a href='https://www.xing.com/pages/quergedachtug'>
                <svg viewBox="0 0 24 24" className="transition duration-700 ease-in-out h-8 md:ml-6 text-qblue hover:text-qorange fill-current"><path d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916a.022.022 0 0 1 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051a.52.52 0 0 0 0 .529c.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z"/></svg>
            </a>

            <a href='https://de.indeed.com/cmp/Quergedacht-Ug/jobs'>
              <svg viewBox="0 0 24 24" className="transition duration-700 ease-in-out h-8 md:ml-6 text-qblue hover:text-qorange fill-current"><path d="M11.566 21.563v-8.762c.255.023.5.035.758.035 1.223 0 2.374-.32 3.35-.893v9.618c0 .822-.195 1.429-.575 1.834-.378.403-.88.605-1.491.605-.6 0-1.077-.202-1.468-.618-.378-.403-.574-1.01-.574-1.819zM11.589.566c2.545-.893 5.442-.845 7.619.987.405.369.867.833 1.051 1.38.22.692-.77-.073-.906-.167-.71-.453-1.418-.833-2.212-1.094C12.86.387 8.812 2.709 6.295 6.315c-1.052 1.594-1.737 3.272-2.3 5.117-.06.202-.109.465-.22.642-.112.203-.048-.546-.048-.57.084-.763.244-1.5.441-2.237C5.33 5.337 7.897 2.066 11.59.566zm4.928 7.059a3.02 3.02 0 1 1-6.04 0 3.02 3.02 0 1 1 6.04 0Z"/></svg>
            </a>

            {
              [
                {
                  route: `/impressum`,
                  title: `Impressum`,
                },
                {
                  route: `/datenschutz`,
                  title: `Datenschutz`,
                }
              ].map((link) =>
                (
                  <Link
                    // className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6 rounded p-2 border hover:border-red-500 hover:bg-blue-500"
                    className="transition duration-700 ease-in-out block mt-4 text-qblue no-underline md:inline-block md:mt-0 md:ml-6 rounded p-2 font-bold hover:text-qorange"
                    key={link.title}
                    to={link.route}
                  >
                    {link.title}
                  </Link>
                )
              )
            }
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
